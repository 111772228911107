

.contact-modal {
    pointer-events: none;
    font-size: 1.4em;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-around;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.contact-modal-visible {
    pointer-events: all;    

    animation: wipe-in 0.5s 1;
    transition-timing-function: ease;
}

.contact-window {
    max-width: 1000px;
    position: fixed;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
    background-color: var(--yellow);
    box-shadow: 1px 1px 1em rgba(50,50,50,0.25);
}

.contact-window div {
    margin: 2em;
    flex-grow: 2;
}

div.contact-portrait {
    margin-bottom: 0;
}

.contact-portrait svg {
    width: 200px;
}

.cheeks {
    opacity: 0;
    fill: pink;
    transition: opacity 1000ms;
    mix-blend-mode: multiply;
  }
  
.contact-portrait:hover .cheeks {
    opacity: 50% !important;
  }

.contact-modal button {
    font-family: inherit;
    font-size: 1em;
    background: none;
    border: none;
    padding: 0;
}
.contact-modal button:hover, .contact-modal button:active {
    color: var(--dark-grey);
    cursor: pointer;
    text-decoration: underline;
}

.curtain {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background: var(--medium-grey);
}

@media screen and (min-width: 1000px) {
    .contact-window {
        border-radius: 2em;
    }

    .contact-portrait svg {
        width: 400px;
    }
    
    .contact-portrait {
        background: url('./simple-dave.svg');
        display: block;
        padding: 0;
    }
}

@keyframes wipe-in {
    0% { left: -50%; }
    100% { left: 0; }
  }