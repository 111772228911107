

nav button {
    font-size: 4.5em;
    font-family: inherit;
    color: var(--dark-grey);
    display: inline;
    border: 0;
    background: none;
    font-weight: 900;
    padding: 0 0 0 0;
    margin-top: 0.3em;
    margin-bottom: 0;
  }

  nav button:hover, nav button:active {
    color: var(--medium-grey);
    cursor: pointer;
  }
  
  nav a, nav:visited {
    text-decoration: none;
    color: inherit;
  }
  
  nav ol {
    margin: 0;
    margin-top: 3em;
    list-style: none;
    padding: 0;
    list-style-position: outside;
  }
  
  nav li {
    opacity: 0.5;
    letter-spacing: -0.05em;
    line-height: 1em;
    margin-bottom: 0.4em;
    color: var(--dark-grey);
    font-size: 3.2em;
    font-weight: 700;
    letter-spacing: -0.05em;
    border-bottom: 1px solid var(--light-grey);
  }
  
  nav li:hover {
    opacity: 1;
    color: var(--dark-grey);
    background-color: var(--yellow);
  }
  
nav li div {
    position: relative;
    pointer-events: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: opacity 400ms;
}

nav li:hover div {
  opacity: 1;
  transition: opacity 400ms;
}

nav li div img {
    position: absolute;
    width: 50%;
    left: 50%;
    height: auto;
    top: -200px;
    animation: hovering 3s 3s infinite;
    -moz-animation: hovering 3s 3s infinite;
    -webkit-animation: hovering 3s 3s infinite;
    -o-animation: hovering 3s 3s infinite;
    transition-timing-function: ease;
    border-radius: 25px;
    box-shadow: 1px 1px 10px rgba(50,50,50,0.25);
}

  nav ol li a:hover {
    opacity: 1;
    transition: 0.5s;
  }

  @media screen and (min-width: 1200px) {
    nav button {
      font-size: 7vw;
    }
    nav li {
      font-size: 5vw;
    }
}

@media screen and (min-width: 1600px) {
    nav ol {
      list-style: upper-roman;
    }
}

@keyframes hovering {
  0% { top: -180px; }
  50% { top: -220px; }
  100% { top: -180px; }
}