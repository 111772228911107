footer {
    margin-bottom: 10em;
  }

  footer {
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  
  footer div {
    flex: 1 0 auto;
    width: 33%;
    align-items: center;
  }
  
  footer ul {
    display: flex;
    padding: 0 0;
    flex-direction: column;
    justify-content: space-between;
  }
  
  footer li {
    display: inline-block;
    line-height: 2em;
    flex-grow: 1;
    padding: 0;
    align-self: center;
  }
  
  footer p {
    color: var(--light-grey);
  }
  
  footer a {
    text-decoration: none;
    color: var(--light-grey);
    transition: color 200ms;
  }
  
  footer a:hover {
    color: var(--dark-grey);

  }
  
  footer .footer-social {
    align-items: right;
    justify-content: flex-end;
    flex-direction: row;
    font-size: 2em;
  }
  
  .footer-social li {
    flex-grow: 0;
    padding-left: 0.5em;
  }