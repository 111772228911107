.about-bar {
    background-color: var(--yellow);
    display: flex;
    align-items: auto;
    font-size: 1.4em;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 100px;
}

  
  .about-bar svg {
    width: 100%;
    border-radius: 100%;
  }
  
  .about-bar button {
    cursor: pointer;
    background-color: var(--yellow);
    border: 3px solid black;
    font-family: inherit;
    font-size: 1em;
    padding: 1em;
  }
  
  .about-bar button:hover {
    text-decoration: underline;
  }
  
  .about-bar div {
    max-width: 70%;
    padding: 1em;
  }

  .about-bar p {
      max-width: 600px;
  }

.st1{fill:none;stroke:#000;stroke-width:4;stroke-miterlimit:10}

.about-bar .cheeks {
  opacity: 0;
  fill: pink;
  transition: opacity 1000ms;
}

.about-bar button:hover ~ .cheeks {
  opacity: 50% !important;
}

@media screen and (min-width: 1000px) {
    .about-bar {
        justify-content: flex-start;
    }
}