.project-description {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: column;
    margin-bottom: 3em;
  }
  
  .project-description div{
    min-width: 40%;
    padding-right: 1em;
    margin-bottom: 2em;
  }
  
  .project-description h1 {
    font-size: 3em;
    line-height: 1.2em;
    margin-bottom: 0;
    margin-right: 1em;
  }
  
  .project-description p {
    font-size: 1em;
    line-height: 1.5em;
    max-width: 450px;
  }

  .project-description p:first-child::first-letter{
    font-family: 'hero-new';
    font-weight: 600;
    font-size: 1.5em;
    initial-letter: 2;
    color: var(--dark-grey);
  }
  
  .project-description ul {
    padding-left: 0;
  }
  
  .project-description li {
    font-size: 0.8em;
    color: var(--medium-grey);
    display: inline-block;
    margin-right: 0.6em;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--light-grey);
  
  }
  
  .project-images img{
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 1.5em;
    text-align: center;
  }
  
  .project-images {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .project-hero {
  
    width: 100%;
  }
  
  .project-hero img {
  
    width: 100%;
  }

  @media screen and (min-width: 1200px) {

  }