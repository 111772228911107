* {
  box-sizing: border-box;
}

:root {
  --yellow: #FFF300;
  --dark-grey: #333;
  --medium-grey: #666;
  --light-grey: #c0c0c0;
  --white-grey: #fcfcfc;
}

body {
  background: var(--white-grey);
  font-family: 'hero-new', sans-serif;
  display: grid;
  place-items: top;
}

.app {
  width: 100%;
}

h1, h2, h3 {
  letter-spacing: -0.03em;
  color: var(--dark-grey);
}

p {
  font-family: 'kopius';
  font-size: 1em;
  line-height: 1.3em;
}

header, nav, footer, .project-description, .container {
  margin: 0 auto;
  padding: 0 1em;
  max-width: 1100px;
}

@media screen and (max-width: 500px) {
  body {font-size: 0.8em;}
}

@media screen and (min-width: 1000px) {

  header, nav, footer, .project-description, .project-images, .container {
    padding: 0;   
    max-width: 80%;
  }

  footer ul {
    flex-direction: row;
  }
  .project-description, .about-bar {
    flex-direction: row;
  }
  
}

@media screen and (min-width: 1400px) {
  nav ol {
    list-style: upper-roman;
  }

  header, nav, footer, .project-description, .container, .project-images {
    max-width: 1150px;
  }
}

  @media screen and (min-width: 2000px) {
    nav ol {

    }
  
    header, nav, footer, .project-description, .container {
      max-width: 1600px;
    }
  
    .project-images {
      max-width: 1600px;
      margin: 0 auto;
    }
  
}