header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4em !important;
    margin-bottom: 1em;
    border-bottom: 1em solid var(--yellow);
  }
  
  header a {
    text-decoration: none;
    color: var(--medium-grey);
  }
  
  header a:hover{
    color: var(--dark-grey);
  }
  
  header h1 {
    font-size: 1.2em;
    font-weight: 800;
    letter-spacing: inherit;
    line-height: 1em;
    margin: 0;
    margin-bottom: 0.1em;
    display: block;
    text-transform: uppercase;
  }
  
  header .header-path {
    display: none;
    text-align: center;
  }
  
  header ul {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    justify-content: space-around;
    align-items: flex-end;
  }
  
  header li {
    
    display: inline-block;
    margin-left: 1em;
  }

  @media screen and (min-width: 1200px) {
    header ul {
      flex-direction: row;
      justify-content: flex-end;
    }
    header .header-path {
      display: block;
    }
}